import fetch from "../auth/FetchInterceptor";

const FCMService = {}

FCMService.createNewFCM = function (data) {
    return fetch({
        crossDomain:true,
        url: '/createFCM',
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
        data: JSON.stringify(data)
    })
}

FCMService.updateFCM = function (data) {
    return fetch({
        crossDomain:true,
        url: '/updateFCM',
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
        data: JSON.stringify(data)
    })
}

FCMService.getFCMData = function (data) {
    return fetch({
        crossDomain:true,
        url: '/FCMData/'+data,
        method: 'get',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    }).then(data => {
        return data
    });
}

FCMService.deleteFCM = function (id) {
    return fetch({
        crossDomain:true,
        url: '/deleteFCM/'+id,
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    })
}

FCMService.changeFCMStatus = function (data) {
    return fetch({
        crossDomain:true,
        url: '/updateFCMStatus/'+data,
        method: 'get',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        }
    })
}


FCMService.sendFCM = function (data) {
    return fetch({
        crossDomain:true,
        url: '/senFCMMessage',
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
        data: JSON.stringify(data)
    })
}


export default FCMService