// import dotenv from "react-dotenv";

const dev = {
	API_ENDPOINT_URL: 'http://localhost:9000/api',
	BASE_URL: 'http://localhost:9000',
};

const qa = {
	API_ENDPOINT_URL: 'https://disruptem-backend.disruptem.com/api',
	BASE_URL: 'https://disruptem-backend.disruptem.com',
};

const getEnv = () => {
	if(!process.env.REACT_APP_ENV) return dev;
	switch (process.env.REACT_APP_ENV) {
		case 'dev':
			return dev
		case 'prod':
			return dev
		case 'qa':
			return qa
		case 'staging':
			return dev
		default:
			break;
	}
}

console.log(process.env)

export const env = getEnv()
console.log(env);