import {
  DashboardOutlined,
  UserOutlined,
  GatewayOutlined,
  PhoneOutlined,
  CalendarOutlined,
  TeamOutlined,
  TranslationOutlined,
  ControlOutlined,
  SendOutlined,
  GlobalOutlined,
  FileOutlined,
  KeyOutlined,
  MailOutlined,
  SolutionOutlined,
  FolderOpenOutlined,
  ContainerOutlined,
  MailFilled
} from "@ant-design/icons";
import {APP_PREFIX_PATH} from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "sidenav.dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboards-default",
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        title: "sidenav.dashboard.default",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-users",
        path: `${APP_PREFIX_PATH}/dashboards/users`,
        title: "sidenav.dashboard.users",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-admins",
        path: `${APP_PREFIX_PATH}/dashboards/admins`,
        title: "sidenav.dashboard.admins",
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "dashboards-admins",
            path: `${APP_PREFIX_PATH}/dashboards/admins`,
            title: "sidenav.dashboard.admins",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "dashboards-contacts",
        path: `${APP_PREFIX_PATH}/dashboards/contacts`,
        title: "sidenav.dashboard.contacts",
        icon: PhoneOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-report-problem",
        path: `${APP_PREFIX_PATH}/dashboards/report_problem`,
        title: "sidenav.dashboard.report_problem",
        icon: FileOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const settingsNavTree = [
  {
    key: "settings",
    path: `${APP_PREFIX_PATH}/settings`,
    title: "sidenav.global_settings",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "settings-payment_gateway",
        path: `${APP_PREFIX_PATH}/settings/payment_gateway`,
        title: "sidenav.global_settings.payment_gateway",
        icon: GatewayOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "settings-fcm_credentials",
        path: `${APP_PREFIX_PATH}/settings/fcm_credentials`,
        title: "sidenav.global_settings.fcm_credentials",
        icon: KeyOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "settings-languages_admin",
        path: `${APP_PREFIX_PATH}/settings/languages`,
        title: "sidenav.global_settings.admin_languages",
        icon: TranslationOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "settings-global-languages",
            path: `${APP_PREFIX_PATH}/settings/languages/global_languages`,
            title: "sidenav.global_settings.global_languages",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "settings-global-languages-screen",
            path: `${APP_PREFIX_PATH}/settings/languages/language_screens`,
            title: "sidenav.global_settings.language_screens",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "settings-global-languages-strings",
            path: `${APP_PREFIX_PATH}/settings/languages/language_strings`,
            title: "sidenav.global_settings.language_strings",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "settings-emails_admin",
        path: `${APP_PREFIX_PATH}/settings/smtp_credentials`,
        title: "sidenav.global_settings.admin_email_settings",
        icon: MailOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "settings-email-settings",
            path: `${APP_PREFIX_PATH}/settings/smtp_credentials`,
            title: "sidenav.global_settings.smtp_credentials",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const appSettingsNavTree = [
  {
    key: "app_settings",
    path: `${APP_PREFIX_PATH}/settings`,
    title: "sidenav.app_settings",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "settings-social_links",
        path: `${APP_PREFIX_PATH}/settings/social_links`,
        title: "sidenav.app_settings.social_links",
        icon: GlobalOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "settings-app_controls",
        path: `${APP_PREFIX_PATH}/settings/app_controls`,
        title: "sidenav.app_settings.app_controls",
        icon: ControlOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'website_settings-work_controls',
    path: `${APP_PREFIX_PATH}/website_settings/work_controls`,
    title: 'sidenav.website_settings.work_controls',
    icon: FolderOpenOutlined,
    submenu: []
  }
];


const erpNavTree = [
  {
    key: "erp.title",
    title: "erp.title",
    breadcrumb: false,
    submenu: [
      {
        key: "erp.settings",
        title: "Settings",
        breadcrumb: false,
        submenu: [
          {
            key: "settings.connect",
            path: `${APP_PREFIX_PATH}/erp/settings/change-token`,
            title: "Change Token",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },{
         key: "erp.accounting",
        title: "Accounting",
        breadcrumb: false,
        submenu: [
          {
            key: "erp.accounting.quote",
            path: `${APP_PREFIX_PATH}/erp/accounting/quotes`,
            title: "Quote",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "erp.accounting.invoice",
            path: `${APP_PREFIX_PATH}/erp/accounting/invoice`,
            title: "invoice",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "erp.accounting.purchase_order",
            path: `${APP_PREFIX_PATH}/erp/accounting/purchase_order`,
            title: "purchase_order",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "erp.order",
        title: "Order",
        breadcrumb: false,
        submenu: [
          {
            key: "order.order",
            path: `${APP_PREFIX_PATH}/erp/order/order`,
            title: "Order",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "erp.order.order-schedule",
            path: `${APP_PREFIX_PATH}/erp/order/order-schedule`,
            title: "Order Schedule",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "erp.order.service-request",
            path: `${APP_PREFIX_PATH}/erp/order/service-request`,
            title: "Service-request",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "erp.order.deleted",
            title: "Deleted",
            breadcrumb: false,
            submenu: [
              {
                key: "erp.order.deleted.order",
                path: `${APP_PREFIX_PATH}/erp/order/deleted/order`,
                title: "Order",
                breadcrumb: false,
                submenu: [],
              },
            ],
          },
        ],
      },
      {
        key: "erp.user",
        title: "User",
        breadcrumb: false,
        submenu: [
          {
            key: "erp.user.user-type",
            path: `${APP_PREFIX_PATH}/erp/user/user-type`,
            title: "user-type",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "erp.user.user-tag",
            path: `${APP_PREFIX_PATH}/erp/user/user-tag`,
            title: "user-tag",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "erp.user.user",
            path: `${APP_PREFIX_PATH}/erp/user/user`,
            title: "user",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "erp.user.group",
            path: `${APP_PREFIX_PATH}/erp/user/user-group`,
            title: "group",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "erp.deleted",
            title: "Deleted",
            breadcrumb: true,
            submenu: [
              {
                key: "erp.user.delete.tag",
                path: `${APP_PREFIX_PATH}/erp/user/deleted/user-tag`,
                title: "User Tag",
                breadcrumb: false,
                submenu: [],
               },
              {
                key: "erp.user.delete.user",
                path: `${APP_PREFIX_PATH}/erp/user/deleted/user`,
                title: "User",
                breadcrumb: false,
                submenu: [],
               },
               {
                key: "erp.user.delete.group",
                path: `${APP_PREFIX_PATH}/erp/user/deleted/user-group`,
                title: "User Group",
                breadcrumb: false,
                submenu: [],
               }
            ],
          },
        ],
      },
    ],
  }
];


const navigationConfig = [
  ...dashBoardNavTree,
  ...erpNavTree,
  ...settingsNavTree,
  ...appSettingsNavTree,
];

export default navigationConfig;
