import fetch from "../auth/FetchInterceptor";

const AdminService = {};

AdminService.createNewAdmin = function (data) {
  return fetch({
    crossDomain: true,
    url: "/createAdmin",
    method: "post",
    headers: {
      Accept: "application/json",
      "public-request": "true",
      "Content-type": "application/json",
      Origin: "*",
      "Access-Control-Allow-Origin": " *",
    },
    data: JSON.stringify(data),
  });
};

AdminService.updateAdmin = function (data) {
  return fetch({
    crossDomain: true,
    url: "/updateAdmin",
    method: "post",
    headers: {
      Accept: "application/json",
      "public-request": "true",
      "Content-type": "application/json",
      Origin: "*",
      "Access-Control-Allow-Origin": " *",
    },
    data: JSON.stringify(data),
  });
};

AdminService.getAdminData = function (data) {
  return fetch({
    crossDomain: true,
    url: "/adminData/" + data,
    method: "get",
    headers: {
      "public-request": "true",
      "Content-type": "application/json",
      Origin: "*",
      "Access-Control-Allow-Origin": " *",
    },
  }).then(data => {
    return data;
  });
};

AdminService.deleteAdmin = function (id) {
  return fetch({
    crossDomain: true,
    url: "/deleteAdmin/" + id,
    method: "get",
    headers: {
      Accept: "application/json",
      "public-request": "true",
      "Content-type": "application/json",
      Origin: "*",
      "Access-Control-Allow-Origin": " *",
    },
  });
};

AdminService.changeAdminStatus = function (data) {
  return fetch({
    crossDomain: true,
    url: "/updateAdminStatus/" + data,
    method: "get",
    headers: {
      "public-request": "true",
      "Content-type": "application/json",
      Origin: "*",
      "Access-Control-Allow-Origin": " *",
    },
  });
};

AdminService.changeAdminLocale = function (data) {
  let params = new URLSearchParams('');

  //Add a third parameter.
  params.set("locale", data.locale);
  console.log(params.toString())

  return fetch({
    crossDomain: true,
    url: "/updateAdminLocale/608bb75f8bdc504610e9e316?"+ params.toString(),
    method: "get",
    headers: {
      "public-request": "true",
      "Content-type": "application/json",
      Origin: "*",
      "Access-Control-Allow-Origin": " *",
    },
  });
};
export default AdminService;
