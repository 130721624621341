const { default: axios } = require("axios")

const ERP_BASE_URL  = "https://erp-dev.disruptem.com/api/v1"

const request = function(){
    return axios.create({
        baseURL: "https://erp-dev.disruptem.com/api/v1",
        headers: {
            'Accept-Language': 'en', 
            Authorization: "Bearer "+getErpToken(),
        }

    })
}


function removeTags(str) {
    if ((str===null) || (str===''))
    return false;
    else
    str = str.toString();
    return str.replace( /(<([^>]+)>)/ig, '');
 }

function setERPToken (token){

    return localStorage.setItem('ERP-TOKEN',token)
}

function getErpToken (){

    return localStorage.getItem('ERP-TOKEN')
}

export default request;

export {
    removeTags,
    ERP_BASE_URL,
    setERPToken,
    getErpToken
}


