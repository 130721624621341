import { combineReducers } from 'redux';
import Auth from './Auth';
import User from './Users';
import Theme from './Theme';
import Gateway from './Gateway';
import Language from './GlobalLanguages';
import AppControl from './AppControls';
import SocialLinks from './SocialLinks';
import FCMCredentials from './FCMCredentials';
import SMTPCredentials from './SMTPCredentials';
import Admins from './Admins';

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    user: User,
    gateway: Gateway,
    language: Language,
    appControl: AppControl,
    socialLinks: SocialLinks,
    FCMCredentials: FCMCredentials,
    SMTPCredentials: SMTPCredentials,
    Admins: Admins,
});

export default reducers;